import React from 'react'
import Layout from '../../components/layout'
import MediaQuery from 'react-responsive'

import {
	Container,
	Section,
	Row,
	Column,
} from '../../components/layouts'

import {
	Title,
	Subtitle,
	Paragraph,
	Image,
	Card,
	BetaLabel
} from '../../components/ui'

import theme from '../../theme/theme'

const {
	securityPage
} = theme.sections

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.scrolledElement = null

		this.state = {
			animateCards: false,
		}

		this.handleScroll = this.handleScroll.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	handleScroll() {
		if (document.getElementById('cardsContainer')) {
			this.scrolledElement = document.getElementById('cardsContainer')

			if ((this.scrolledElement.offsetTop - (window.innerHeight / 2)) < window.scrollY) {
				this.setState({
					animateCards: true
				})
			}
		}
	}

	render() {
		return (
			<Layout>
				{/* Forward Thinking section */}
				<Section bg={securityPage.bg} paddingTop={'7em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{securityPage.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'370px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>{securityPage.content.paragraph}</Paragraph>
							</Column>
						</Row>
						<Row>
							<Column maxWidth={'50%'}>
								<Card className={this.state.animateCards ? 'animate' : false} style={{display: 'flex', height: 'unset'}}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{securityPage.features.first.title}</Subtitle>
											<Paragraph fontSize={'14px'}>{securityPage.features.first.paragraph}</Paragraph>
										</Column>
									</Row>
								</Card>
								<Card className={this.state.animateCards ? 'animate' : false} style={{display: 'flex', height: 'unset'}}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{securityPage.features.second.title}</Subtitle>
											<Paragraph fontSize={'14px'}>{securityPage.features.second.paragraph}</Paragraph>
										</Column>
									</Row>
								</Card>
							</Column>
							<Column maxWidth={'50%'}>
								<Card className={this.state.animateCards ? 'animate' : false} style={{display: 'flex', height: 'unset'}}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{securityPage.features.third.title}</Subtitle>
											<Paragraph fontSize={'14px'} dangerouslySetInnerHTML={{ __html: securityPage.features.third.paragraph }} />
										</Column>
									</Row>
								</Card>
								<Card className={this.state.animateCards ? 'animate' : false} style={{display: 'flex', height: 'unset'}}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{securityPage.features.fourth.title}</Subtitle>
											<Paragraph fontSize={'14px'} dangerouslySetInnerHTML={{ __html: securityPage.features.fourth.paragraph }} />
										</Column>
									</Row>
								</Card>
							</Column>
						</Row>
					</Container>
				</Section>
			</Layout>
		)
	}
}

export default IndexPage
